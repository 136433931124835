import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";

// Style
import "./Header.css";
import "../styles/buttons.css";

// Components
import ContactModal from "./ContactModal";

// Images and icons
import logo from "../assets/images/logo-dark.svg";
import MenuBurger from "../assets/icons/menu-burger.svg";
import MenuClose from "../assets/icons/menu-close.svg";
import ChevronDown from "../assets/icons/chevron-down.svg";

// @ts-ignore
const NavLink = (props: any) => <Link activeClassName="active" {...props} />;

const DropdownMenu = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setisOpen] = useState<boolean>(false);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  const handleOutsideClick = (event: any) => {
    if (isOpen && ref.current && !ref.current.contains(event.target)) {
      setisOpen(false);
    }
  };

  return (
    <div className={`${"dropdown-nav"} ${isOpen ? "active" : ""}`} ref={ref}>
      <button
        className="btn btn-nav-dropdown"
        onClick={() => setisOpen(!isOpen)}
      >
        <span>{title}</span>
        <img src={ChevronDown} alt="Skodel" />
      </button>
      <div className="dropdown-nav-wrapper">{children}</div>
    </div>
  );
};

const Header = () => {
  const [isModalContact, setIsModalContact] = useState<boolean>(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);

  return (
    <>
      <div className="header-container">
        <div
          className={`${"header-wrapper"} ${isMobileMenuOpen ? "expand" : ""}`}
        >
          <div className="header-mobile">
            <div className="header-logo">
              <NavLink to="/">
                <img src={logo} alt={"Skodel"} />
              </NavLink>
            </div>
            <div
              className="header-menu-button"
              onClick={() => setIsMobileMenuOpen(true)}
            >
              <img src={MenuBurger} alt={"Skodel"} />
            </div>
          </div>
          <div className="header-desktop">
            <div
              className="header-close-button"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              <img src={MenuClose} alt={"Skodel"} />
            </div>
            <div className="header-logo">
              <NavLink to="/">
                <img src={logo} alt={"Skodel"} />
              </NavLink>
            </div>
            <div className="header-menu">
              <div className="header-nav">
                {/* Solutions */}
                <DropdownMenu title="Solutions">
                  <>
                    <NavLink to="/education">Education</NavLink>
                    <NavLink to="/workplaces">Workplaces</NavLink>
                    <NavLink to="/psychosocial-hazards">
                      Psychosocial Hazards
                    </NavLink>
                  </>
                </DropdownMenu>

                {/* Company */}
                <DropdownMenu title="Company">
                  <>
                    <NavLink to="/about-us">About Us</NavLink>

                    <NavLink to="/pricing">Pricing</NavLink>
                  </>
                </DropdownMenu>

                {/* Resources */}
                <DropdownMenu title="Resources">
                  <>
                    <a
                      href="https://publish.obsidian.md/skodel-help"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>Knowledge base</span>
                    </a>
                    <a
                      href="https://blog.skodel.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>Skodel blog</span>
                    </a>
                  </>
                </DropdownMenu>
              </div>
            </div>
            <div className="header-demo">
              <div className="header-nav">
                <a
                  href="https://app.skodel.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>Sign In</span>
                </a>
                <NavLink to="/request-a-demo" className="btn btn-main">
                  Book A Demo
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalContact && (
        <ContactModal onClickClose={() => setIsModalContact(false)} />
      )}
    </>
  );
};
export default Header;
