import * as React from "react";
// import { Global, css } from "@emotion/core";
import styled from "@emotion/styled";

const StyledLayoutRoot = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  background-color: #fff;
`;

interface LayoutRootProps {
  className?: string;
}

const LayoutRoot: React.FC<LayoutRootProps> = ({ children, className }) => (
  <>
    {/* <Global styles={() => css(normalize)} /> */}
    <StyledLayoutRoot className={className}>{children}</StyledLayoutRoot>
  </>
);

export default LayoutRoot;
