import React from "react";
import NetlifyForm from "react-netlify-form";

// Style
import "./ContactModal.css";

// Images and icons
import MenuClose from "../assets/icons/menu-close.svg";

interface ContactModalProps {
  onClickClose?: any;
}

const ContactModal: React.FC<ContactModalProps> = ({ onClickClose }) => {
  return (
    <NetlifyForm name="Contact Form">
      {({ loading, error, success }) => (
        <div>
          <div className="modal modal-contact active">
            <div className="modal-close" onClick={() => onClickClose()}></div>
            <div className="modal-container">
              <div className="modal-wrapper">
                <div
                  className="modal-close-button"
                  onClick={() => onClickClose()}
                >
                  <img src={MenuClose} alt={"Skodel"} />
                </div>
                {success && <div>Thank you for contacting us!</div>}
                {!success && (
                  <div className="modal-body">
                    <h3>Contact us</h3>
                    <p>
                      Fill in the form below and we will get back to you soon.
                    </p>
                    <div className="contact-form-wrapper">
                      <div className="form-wrapper">
                        <div className="form-group">
                          <label>Name</label>
                          <input
                            className="form-input"
                            type="text"
                            placeholder="Enter your name"
                            name="name"
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            className="form-input"
                            type="email"
                            placeholder="Enter your email address"
                            name="email"
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label>Message</label>
                          <textarea
                            className="form-input"
                            placeholder="Enter your message"
                            name="message"
                            required
                          />
                        </div>
                        {error && (
                          <div className="error-msg">
                            Your information was not sent. Please try again
                            later.
                          </div>
                        )}
                        <div className="form-group">
                          <button
                            className="btn btn-main btn-contact btn-full"
                            disabled={loading}
                          >
                            {loading ? "Sending..." : "Send"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </NetlifyForm>
  );
};
export default ContactModal;
