import * as React from "react";

// Style
import "./Footer.css";
import "../styles/buttons.css";

// Images
import logo from "../assets/images/logo-dark.svg";

interface FooterProps {
  title: string;
}

const currentDate = new Date();

const Footer: React.FC<FooterProps> = ({ title }) => (
  <div>
    <div className="footer-container">
      <div className="footer-wrapper">
        <div className="footer-left">
          <div className="footer-logo">
            <a href="https://skodel.com">
              <img src={logo} alt={title} />
            </a>
          </div>
          <a href="mailto:info@skodel.com">info@skodel.com</a>
        </div>
        <div className="footer-right">
          <div className="footer-menu">
            <h3>Solutions</h3>
            <ul>
              <li>
                <a href="/education">Education</a>
              </li>
              <li>
                <a href="/workplaces">Workplaces</a>
              </li>
            </ul>
          </div>
          <div className="footer-menu">
            <h3>Company</h3>
            <ul>
              <li>
                <a href="/about-us">About us</a>
              </li>
              <li>
                <a href="/pricing">Pricing</a>
              </li>
            </ul>
          </div>
          <div className="footer-menu">
            <h3>Resources</h3>
            <ul>
              <li>
                <a
                  href="https://publish.obsidian.md/skodel-help"
                  target="_blank"
                >
                  Knowledge base
                </a>
              </li>
              <li>
                <a href="https://blog.skodel.com/" target="_blank">
                  Skodel blog
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-bottom">
      <div>
        <p>&copy; Skodel {currentDate.getFullYear()}. All rights reserved</p>
      </div>
      <div className="tou-container">
        <a href="/tou.pdf">Terms of Use</a>
        <a href="/privacy-policy.pdf">Privacy Policy</a>
      </div>
    </div>
  </div>
);

export default Footer;
