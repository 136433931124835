import * as React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { MDXProvider } from "@mdx-js/react";

// Styles
import "normalize.css";
import "../styles/main.css";
import "../styles/styles.module.css";

// Components
import GlobalStyles from "../components/GlobalStyles";
import Header from "../components/Header";
import LayoutRoot from "../components/LayoutRoot";
import LayoutMain from "../components/LayoutMain";
import Footer from "../components/Footer";
import HomeHero from "../assets/images/home-hero.jpg";

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string;
      description: string;
    };
  };
}

interface IndexLayoutProps {
  children?: React.ReactNode;
  hideLinks?: boolean;
  withBlackLogo?: boolean;
  workplacesHeader?: boolean;
  signupHref?: string;
}

const META_TAGS = {
  title: "Achieve continuous psychosocial compliance",
  description:
    "Skodel helps busy people and safety leaders meet psychosocial requirements without disrupting other priorities.",
};

const IndexLayout: React.FC<IndexLayoutProps> = ({ children }) => (
  <StaticQuery
    query={graphql`
      query IndexLayoutQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => (
      <LayoutRoot>
        <GlobalStyles>
          <MDXProvider
            components={{
              hubspot: (props) => (
                <p {...props} style={{ color: "rebeccapurple" }}>
                  hi
                </p>
              ),
            }}
          >
            <Helmet>
              <meta
                name="google-site-verification"
                content="Zv0rXFZsb0xla0F_3yokodLBuJQ2sIzvVOn-s3Z6CXI"
              />
              {/*  Primary Meta Tags */}
              <title>{META_TAGS.title}</title>
              <meta name="title" content={META_TAGS.title} />
              <meta name="description" content={META_TAGS.description} />

              {/*  Open Graph / Facebook  */}
              <meta property="og:type" content="website" />
              <meta property="og:url" content="https://skodel.com/" />
              <meta property="og:title" content={META_TAGS.title} />
              <meta property="og:description" content={META_TAGS.description} />
              <meta property="og:image" content={HomeHero} />

              {/*  Twitter */}
              <meta property="twitter:card" content="summary_large_image" />
              <meta property="twitter:url" content="https://skodel.com/" />
              <meta property="twitter:title" content={META_TAGS.title} />
              <meta
                property="twitter:description"
                content={META_TAGS.description}
              />
              <meta property="twitter:image" content={HomeHero} />
            </Helmet>
            <LayoutMain>
              <div style={{ position: "relative", overflow: "hidden" }}>
                <Header />
                {children}
              </div>
            </LayoutMain>
            <Footer title={data.site.siteMetadata.title} />
          </MDXProvider>
        </GlobalStyles>
      </LayoutRoot>
    )}
  />
);

export default IndexLayout;
